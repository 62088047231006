<div class="pb-text-input {{classes}}">
    <div class="textbox-border {{hasErrors?'error':''}} {{this.multiLine?'multiLine':''}}">
        <span class="small-placeholder {{showPlaceholder===true && hasErrors===false?'show':''}}">{{caption}}</span>
        <span class="small-error {{hasErrors===true?'show':''}}">{{errorText}}</span>
        <span *ngIf="this.prefix" class="prefix {{showPlaceholder===true || hasErrors?'show':''}}">{{this.prefix}}</span>
        <input *ngIf="!this.multiLine" [disabled]="isDisabled" 
            #input [type]="this.textBoxType" 
            [attr.name]="name" 
            class="text-input {{showPlaceholder===true || hasErrors===true?'ph-visible':''}}" 
            [attr.placeholder]="placeholder"
            [(ngModel)]="binding"
            (ngModelChange)="onBindingChange($event)"
            (focus)="this.OnFocus(true)"
            (blur)="this.OnFocus(false)"
            (change) = "this.OnTextChanged($event)"
            [maxlength]="this.maxLength"
            [appFocus]="this.focus"
            />

            <textarea *ngIf="this.multiLine" [disabled]="isDisabled" [attr.name]="name" [attr.placeholder]="placeholder" [(ngModel)]="binding" class="text-input {{showPlaceholder===true || hasErrors===true?'ph-visible':''}}"  (focus)="this.OnFocus(true)" (blur)="this.OnFocus(false)" (change) = "this.OnTextChanged($event)" [maxlength]="this.maxLength"></textarea>

            


            <a *ngIf="this.clearIcon" class="clear-button {{showClearIcon===true?'show':''}}" tabindex="-1" (click)="ClearText()">
                <span class="clear-icon"></span>
            </a>
    </div>
</div>