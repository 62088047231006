import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';

@Directive({
    selector: '[appFocus]',
    standalone: true
})
export class FocusDirective implements OnInit {
  @Input('appFocus') isFocused: boolean;

  constructor(private hostElement: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.isFocused) {
      // Use the renderer to ensure compatibility across different platforms
      this.renderer.selectRootElement(this.hostElement.nativeElement).focus();
    }
  }
}