import { NgIf } from '@angular/common';
import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GqlError } from 'src/app/services/graphql.module/model';
import { FocusDirective } from '../../directives/appfocus.directive';

@Component({
    selector: 'pb-textbox',
    templateUrl: './pb-textbox.component.html',
    styleUrls: ['./pb-textbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, FormsModule, FocusDirective]
})


export class PbTextboxComponent implements OnInit {

  constructor() {
    
   }
  
  showPlaceholder:boolean=false;
  showClearIcon: boolean = false;
  isFocused :boolean = false;
  isNumber:boolean = false;
  isPassword:boolean = false;

  @Input() name: string;
  @Input() multiLine:boolean = false;
  @Input() caption: string;
  @Input() classes: string;
  @Input() placeholder: string = "";
  @Input() isDisabled: boolean = false;
  @Output() bindingChange = new EventEmitter<string>();
  @Output() blur = new EventEmitter<any>();
  @Output() onFocus = new EventEmitter<any>();
  @Input() prefix = "";
  @Input() maxLength = 524288;
  @Input() clearIcon:boolean = true;
  @Input() focus:boolean = false;
  
  
  height: '50px';

  hasErrors = false;
  
  private _numbersOnly:boolean;
  private _password:boolean;
  private  _gqlErrors : Array<GqlError>;
  errorStrings: Array<string> = new Array<string>();

  @Input() set gqlErrors (value: Array<GqlError>){
      this._gqlErrors = value;
      this.errorStrings = [];

      if (this._gqlErrors != undefined) {
        this._gqlErrors.filter(e => e.source == this.name).forEach(e => this.errorStrings.push(e.message));
        if (this.errorStrings.length>0){
          this.hasErrors = true;
        }
        else{
          this.hasErrors = false;
        }
      }
      else{
        this.hasErrors = false;
      }
  };

  get gqlErrors():Array<GqlError>{
    return this._gqlErrors;
  }

  get textBoxType(){

    if (this.isPassword){
      return "password"
    }

    if (this.isNumber){
      return "number";
    }

    return "text";
    
  }

  @Input() set numbersOnly (value:boolean){
    this._numbersOnly = value;
    if (value){
      this.isNumber  = true;
    }else{
      this.isNumber = false;
    }
  }

  get numbersOnly(){
    return this._numbersOnly;
  }


  @Input() set password (value:boolean){
    this._password = value;
    if (value){
      this.isPassword  = true;
    }else{
      this.isPassword = false;
    }
  }

  get password(){
    return this._password;
  }


  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();


  
  get errorText():string{
    return this.errorStrings.join(', ');
  }

  @ViewChild('input') input: ElementRef;

  private _binding: string;

  @Input() set binding(value: string) {
    this._binding = value;
    // if (value && value.toString().length>0 || value && value.toString() == "0"){
      if (value !== null && value !== undefined && value.toString().length > 0){
      this.bindingChange.emit(value.toString());
      this.SetPlaceHolderVisibility();
    }
    
  }

  get binding(): string {
      return this._binding;
  }
  
  onBindingChange(newValue: string) {
    this._binding = newValue;
    this.bindingChange.emit(newValue);
    this.SetPlaceHolderVisibility();
    this.valueChange.emit(newValue); // Emit the valueChange event here
  }



  OnFocus(isFocused: boolean): void {
    this.isFocused = isFocused; 
    this.SetPlaceHolderVisibility();
    if (!isFocused){
      this.blur.emit();
    }
    else{
      this.onFocus.emit();
    }
  }

  OnTextChanged(event: Event):void{
    this.SetPlaceHolderVisibility();
    this.hasErrors = false;
    const newValue = (event.target as HTMLInputElement).value;
    this.valueChange.emit(newValue);
  }

  SetPlaceHolderVisibility(){
    this.showClearIcon = this.isFocused && this.binding && this.binding.length>0;
    this.showPlaceholder = this.isFocused || (this.binding && this.binding.toString().length>0);
  }

  ClearText(){
    this.bindingChange.emit("");
    // setTimeout(() => {
      this.input.nativeElement.focus();
    // });
  }

  


  ngOnInit(): void {

  }

}

